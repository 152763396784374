<template>
  <b-sidebar
    id="add-new-session-sidebar"
    :visible="isAddNewSessionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-session-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New Session</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Name -->
          <validation-provider #default="validationContext" name="Session Name" rules="required">
            <b-form-group label="Session Name" label-for="session-name">
              <b-form-input
                id="session-name"
                v-model="sessionData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="DevOps Pathway"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Visibility -->
          <validation-provider #default="validationContext" name="Visibility" rules="required">
            <b-form-group label="Visibility" label-for="visibility" :state="getValidationState(validationContext)">
              <v-select
                v-model="sessionData.visibility"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="visibilityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="session-visibility"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
              <v-select
                v-model="sessionData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="session-status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button-group class="w-100">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" type="button" variant="outline-secondary" @click="hide">
                Cancel
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" type="submit">
                Create
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                :to="{ name: 'apps-roster-session-builder' }"
              >
                Builder (beta)
              </b-button>
            </b-button-group>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BButtonGroup } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import countries from '@/@fake-db/data/other/countries';
import store from '@/store';
import router from '@/router';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BButtonGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSessionSidebarActive',
    event: 'update:is-add-new-session-sidebar-active',
  },
  props: {
    isAddNewSessionSidebarActive: {
      type: Boolean,
      required: true,
    },
    visibilityOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankSessionData = {
      name: '',
      visibility: 'internal',
      status: 'created',
    };

    const sessionData = ref(JSON.parse(JSON.stringify(blankSessionData)));
    const resetsessionData = () => {
      sessionData.value = JSON.parse(JSON.stringify(blankSessionData));
    };

    const onSubmit = () => {
      console.log(`submit session data`, sessionData.value);
      store.dispatch('app-roster/addSession', { sessionData: sessionData.value }).then((response) => {
        emit('update:is-add-new-session-sidebar-active', false);
        emit('refetch-data');

        if (response.data.id) {
          router.push({ name: 'apps-roster-session-view', params: { id: response.data.id } });
        }
      });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetsessionData);

    return {
      sessionData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-session-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
